// Adapted from https://github.com/ghewgill/picomath/blob/master/javascript/erf.js
function erf(x) {

    // constants
    const a1 = 0.254829592;
    const a2 = -0.284496736;
    const a3 = 1.421413741;
    const a4 = -1.453152027;
    const a5 = 1.061405429;
    const p = 0.3275911;

    // A&S formula 7.1.26
    const t = 1.0 / (1.0 + p * Math.abs(x));
    const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

    return Math.sign(x) * y;
}

function def_int_gaussian(x, mu, sigma) {
    return 0.5 * erf((x - mu) / (Math.SQRT2 * sigma));
}

export function gaussian_kernel(kernel_size = 5, sigma = 2) {
    const mu = 0
    const step = 1
    const end = 0.5 * kernel_size;
    const start = -end;
    const coeff = [];
    let sum = 0;
    let x = start;
    let last_int = def_int_gaussian(x, mu, sigma);
    let acc = 0;
    while (x < end) {
        x += step;
        const new_int = def_int_gaussian(x, mu, sigma)
        let c = new_int - last_int;
        coeff.push(c);
        sum += c;
        last_int = new_int;
    }

    // normalize
    sum = 1 / sum;
    for (let i = 0; i < coeff.length; i++) {
        coeff[i] *= sum;
    }

    return coeff;
}

export function conv2d(x, kernel) {
  let channels = 4 // RGBA
  let dim = channels * x.width * x.height // 4 RBGA int8s
  let img = new Uint8ClampedArray(dim) // flattened

  // i rows, j column
  for (let i = 0; i < x.height; i++) {
    for (let j = 0; j < x.width; j++) {
      let  pxr = 0., pxg = 0., pxb = 0., pxa = 0.
      for (let k = 0; k < 5; k++) {
        for (let l = 0; l < 5; l++) {
          // px position of current kernel select in original img
          let oi = i + k - 2
          let oj = j + l - 2
          if (oj >= 0 && oj < x.width && oi >= 0 && oi < x.height) {
            let base = channels * (oi * x.width + oj)
            let weight = kernel[k * 5 + l]
            pxr += weight * x.data[base + 0]
            pxg += weight * x.data[base + 1]
            pxb += weight * x.data[base + 2]
            pxa += weight * x.data[base + 3]
          }
        }
      }

      let base = channels * (i * x.width + j)
      img[base + 0] = Math.round(pxr)
      img[base + 1] = Math.round(pxg)
      img[base + 2] = Math.round(pxb)
      img[base + 3] = Math.round(pxa)
    }
  }

  return img
}

export function outer_product(a, b) {
  console.assert(a.length == b.length, 'a.length != b.length')
  let ret = new Float32Array(a.length * b.length)
  for (let i = 0; i < a.length; i++) {
    for (let j = 0; j < b.length; j++) {
      ret[i * b.length + j] = a[i] * b[j]
    }
  }

  return ret
}
