import CenterImg from '../../../../src/components/articles/CenterImg';
import LeftImg from '../../../../src/components/articles/LeftImg';
import RightImg from '../../../../src/components/articles/RightImg';
import Advert from '../../../../src/components/Advert';
import imgCLevel from "../../../../content/blog/articles/what-is-a-data-platform/c-level.svg";
import imgBelowCLevel from "../../../../content/blog/articles/what-is-a-data-platform/below-c-level.svg";
import imgStep1 from "../../../../content/blog/articles/what-is-a-data-platform/step-1.svg";
import imgStep2 from "../../../../content/blog/articles/what-is-a-data-platform/step-2.svg";
import imgStep3 from "../../../../content/blog/articles/what-is-a-data-platform/step-3.svg";
import imgFileMess from "../../../../content/blog/articles/what-is-a-data-platform/file-mess.png";
import imgPeopleMess from "../../../../content/blog/articles/what-is-a-data-platform/people-mess.svg";
import imgDataPlatform from "../../../../content/blog/articles/what-is-a-data-platform/data-platform.svg";
import * as React from 'react';
export default {
  CenterImg,
  LeftImg,
  RightImg,
  Advert,
  imgCLevel,
  imgBelowCLevel,
  imgStep1,
  imgStep2,
  imgStep3,
  imgFileMess,
  imgPeopleMess,
  imgDataPlatform,
  React
};