/* Sidenote
 *
 */

import React from 'react'

import styles from "./Sidenote.module.styl"

export default class Sidente extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { children } = this.props
    return (
      <div className={ styles.sidenote }>
        <div className={ styles.title }>Note</div>
        { children }
      </div>
    )
  }
}
