import React from 'react'

import styles from "./AudioPlayer.module.styl"

export default function AudioPlayer(props) {
  return (
    <audio controls preload="none" className={ styles.player } src={ props.src }>
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  )
}
