/* Advertisment
 *
 */

import React from 'react'
import { Link } from 'gatsby'
import styles from './Advert.module.styl'

export default class Advert extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if(this.props.service === 'data-foundations') {
      return (
        <div className={ styles.ad }>
          <Link to="/services/data-foundations"><span>FELD can help with your data foundations.</span> Read</Link>
        </div>
      )
    } else if(this.props.service === 'research-engineering') {
      return (
        <div className={ styles.ad }>
          <Link to="/services/research-engineering"><span>Hire a research engineer</span>&nbsp; Read more</Link>
        </div>
      )
    } else {
      return <span></span>
    }
  }
}
