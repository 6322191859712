import React from 'react'

import scholar from "./scholar.svg"
import styles from "./ScholarUser.module.styl"

export default function ScholarUser(props) {
  return (
    <span className={ styles.scholar }>
      <a href={ 'https://scholar.google.com/citations?user=' + props.profile }>
        { props.name } <img src={ scholar } alt="google scholar profile" />
      </a>
    </span>
  )
}
