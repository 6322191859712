import React, { useRef, useEffect } from 'react'

import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  AreaSeries,
  MarkSeries } from 'react-vis'

import styles from './Explain.module.styl'

// colors
const Burgundy = "#e27475"
const Red = "#ee0000"
const Blue = "#0085ff"
const Purple = "#4200ff"

const curvedBoundary = [
  {x: 2.0, y:  0.0},
  {x: 2.0, y:  8.0},
  {x: 2.5, y: 12.0},
  {x: 3.0, y: 13.0}
]

const linearBoundary = [
  {x: 2.0, y:  8.3},
  {x: 2.8, y: 11.3},
]

const supportExamples = [
  {x: 2.2, y: 10.0, size: 1.1},
  {x: 2.7, y: 12.0, size: 0.1},
  {x: 2.2, y:  8.0, size: 0.8},
  {x: 2.7, y: 10.0, size: 0.4}
]

const targetExample = [
  {x: 2.4, y: 9.2, size: 2.0},
]

export default class Explain extends React.Component {
  constructor(props) {
    super(props)
    this.state = { data: curvedBoundary, linear: false }
    this.side = 180
  }

  toggleData = () => {
    this.setState({
      linear: !this.state.linear,
      data: this.state.linear ? curvedBoundary : linearBoundary,
      samples: this.state.linear ? [] : supportExamples,
    })
  }

  render() {
    return (
      <figure className={ styles.explain }
           onMouseEnter={ this.toggleData }
           onMouseLeave={ this.toggleData }
           onClick={ this.toggleData } >
        <XYPlot width={ this.side } height={ this.side } animation={ true }>
          <YAxis style={{ text: { fontSize: 11, fill: Red } }}/>
          <XAxis style={{ text: { fontSize: 11, fill: Red } }}/>
          <AreaSeries
            curve="curveNatural"
            fill={ Burgundy }
            stroke={ Red }
            style={{ strokeWidth: 3 }}
            data={ this.state.data } />
        <MarkSeries
          strokeWidth={ 2 }
          opacity="1.0"
          color={ Purple }
          sizeRange={ [5, 13] }
          data={ this.state.samples } />
        <MarkSeries
          strokeWidth={ 2 }
          opacity="1.0"
          color={ Blue }
          data={ targetExample} />
        </XYPlot>
        <figcaption>Fig 3. Hover or touch 🤔</figcaption>
      </figure>
    )
  }
}
