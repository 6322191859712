/* CBS viz
 *
 */

import React, { useRef, useEffect } from 'react'
import { XYPlot, VerticalBarSeries } from 'react-vis';

import * as Math from '../../../../src/utils/math'
import { debounce } from '../../../../src/utils/core'
import LeftImg from '../../../../src/components/articles/LeftImg'
import styles from './CBS.module.styl'
import duerer from './duerer.png'


export default class CBS extends React.Component {
  constructor(props) {
    super(props)
    this.imgRef = React.createRef();
    this.imgCanvasRef = React.createRef();
    this.gaussianCanvasRef = React.createRef();
    this.process = debounce(this.processImg, 400)
    this.state = { std: 10., kernel: Math.gaussian_kernel(5, 0.6) }
    this.height = 181
    this.width = 246
  }

  componentDidMount() {
    this.process()
  }

  componentDidUpdate() {
    this.process()
  }

  std = () => {
    return this.state.std / 25
  }

  loadImg = () => {
    let imgCtx = this.imgCanvasRef.current.getContext('2d')
    imgCtx.drawImage(this.imgRef.current, 0, 0)
  }

  processImg = () => {
    let ctx = this.imgCanvasRef.current.getContext('2d')
    let img = this.imgRef.current
    ctx.drawImage(img, 0, 0)
    const imgData = ctx.getImageData(0, 0, this.width, this.height)
    let two_dim = Math.outer_product(this.state.kernel, this.state.kernel)
    let array = Math.conv2d(imgData, two_dim)
    let convolved = new ImageData(array, imgData.width)
    ctx.putImageData(convolved, 0, 0);
  }

  slide = (ev) => {
    let std = ev.target.value
    this.setState((state) => ({
      std: std,
      kernel: Math.gaussian_kernel(5, std/25)
    }))
  }

  render() {
    return (
      <div className={ styles.cbs }>
        <div className={ styles.highlight }>
          Suddenly, I found myself implementing convolutions in javascript.
          Anything to avoid writing? Use the slider to see the
          effect of annealing the standard deviation on the kernel and image.
        </div>
        <figure className={ styles.distribution }>
          <XYPlot
            color={ '#ffd099' }
            height={ this.height / 1.5 }
            width={ this.width }>
            <VerticalBarSeries
              data={ this.state.kernel.map(function(y, i) { return { x: i, y: y} }) }
              style={{ strokeWidth: 2, stroke: '#ff8a00' }}
            />
          </XYPlot>
          <input
            style={{ width: this.width }}
            type="range"
            min="0.1"
            max="100"
            defaultValue={ this.state.std }
            onChange={ this.slide } />
          <figcaption>
            Slide me 🤔 <span> { this.std().toFixed(2) }</span>
          </figcaption>
        </figure>
        <figure className={ styles.rhino }>
          <img style={{ display: 'none' }}
               src={ duerer }
               ref={ this.imgRef }
               onLoad={ this.loadImg } />
          <canvas
            ref={ this.imgCanvasRef }
            height={ this.height }
            width={ this.width }>
          </canvas>
          <figcaption>
            Fig 1. Dürer's Rhinoceros
          </figcaption>
        </figure>
      </div>
    )
  }
}
