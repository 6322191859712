/* Centered image
 *
 */

import React from 'react'

import styles from "./CenterImg.module.styl"

export default class CenterImg extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={ styles.centerImg }>
        <figure>
          <img src={ this.props.img } className={ this.props.border ? 'border' : '' } />
          { this.props.caption ? <figcaption>{ this.props.caption }</figcaption> : '' }
        </figure>
      </div>
    )
  }
}
