import { TwitterTweetEmbed } from 'react-twitter-embed';
import LeftImg from '../../../../src/components/articles/LeftImg';
import CenterImg from '../../../../src/components/articles/CenterImg';
import Sidenote from '../../../../src/components/articles/Sidenote';
import T from '../../../../src/components/TwitterUser';
import S from '../../../../src/components/ScholarUser';
import AudioPlayer from '../../../../src/components/AudioPlayer';
import CBS from "../../../../content/blog/articles/neurips-2020/CBS.js";
import Explain from "../../../../content/blog/articles/neurips-2020/Explain.js";
import imgCompas from "../../../../content/blog/articles/neurips-2020/compas.png";
import imgWav2vec from "../../../../content/blog/articles/neurips-2020/wav2vec.svg";
import imgMultiformat from "../../../../content/blog/articles/neurips-2020/multiformat.svg";
import imgBert from "../../../../content/blog/articles/neurips-2020/bert.jpg";
import imgBart from "../../../../content/blog/articles/neurips-2020/bart.svg";
import * as React from 'react';
export default {
  TwitterTweetEmbed,
  LeftImg,
  CenterImg,
  Sidenote,
  T,
  S,
  AudioPlayer,
  CBS,
  Explain,
  imgCompas,
  imgWav2vec,
  imgMultiformat,
  imgBert,
  imgBart,
  React
};