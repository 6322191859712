import React from 'react'

import twttr from "./twttr.svg"
import styles from "./TwitterUser.module.styl"

export default function TwitterUser(props) {
  return (
    <span className={ styles.twttr }>
      <a href={ 'https://twitter.com/' + props.profile }>
        { props.name } <img src={ twttr } alt="twitter profile" />
      </a>
    </span>
  )
}
