import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCs6mK_qWZP8-bPLEQyF2aPIF4-2vtQNm8",
  authDomain: "feld-web-6a451.firebaseapp.com",
  databaseURL: "https://feld-web-6a451.firebaseio.com",
  projectId: "feld-web-6a451",
  storageBucket: "feld-web-6a451.appspot.com",
  messagingSenderId: "88102277789",
  appId: "1:88102277789:web:87eaefbac676f5f56fba74",
  measurementId: "G-H31W0FPGVX"
};

export default firebase.initializeApp(firebaseConfig)
