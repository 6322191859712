import "./src/styles/global.styl"
import "katex/dist/katex.min.css"

import firebase from './src/utils/firebase'

import { wrapRootElement as wrap } from './wrap-root-element'

export const onClientEntry = async () => {
  import('firebase/analytics').then(function() {
    firebase.analytics()
  })
}

export const wrapRootElement = wrap
